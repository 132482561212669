<template>
  <div id="healthInsurance">
    <section
      class="page-header"
      style="background-image: url('/imgs/health-insurance-01.png')"
    >
      <div class="caption">
        <h4>{{ $lang("title") }}</h4>
        <i class="isologo"></i>
        <h1>{{ $lang("subtitle") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container">
        <p class="max-w-5">{{ $lang("desc") }}</p>
      </div>
    </section>

    <section class="section-14 py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-6">
            <h4 class="text-primary mb-5 lh-3">
              {{ $lang("features.title") }}
            </h4>
            <div v-html="$lang('features.desc')" />
          </div>
          <div class="col-lg-4 col-md-6 pt-5 pt-md-0">
            <div
              class="img-container img-container-rounded h-100 minh-5"
              style="background-image: url('/imgs/health-insurance-02.png')"
            ></div>
          </div>
          <div class="col-lg-8">
            <div class="row mt-5">
              <div class="col-md-4">
                <h4 class="text-primary mt-md-4 mb-3 lh-3">
                  {{ $lang("features.subtitle") }}
                </h4>
              </div>
              <div class="col-md-8" v-html="$lang('features.table')"></div>
            </div>
          </div>
          <div class="col-12 text-center pt-5">
            <button
              class="btn btn-primary px-5"
              @click="$router.push('/contact-us')"
            >
              {{ $lang("features.button") }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
